import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/Users/jxie/projects/lyra/packages/web/src/components/content/ContentPage.tsx";
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const PandemicInformation = makeShortcode("PandemicInformation");
const SingleImage = makeShortcode("SingleImage");
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h1 {...{
      "id": "managing-disagreements-and-differences"
    }}>{`Managing disagreements and differences`}</h1>
    <p>{`Conflict is a part of life. But arguments with your teenager or your partner can leave those involved feeling very upset. Being part of a family where there is frequent or unresolved conflict and lingering resentment increases a young person’s risk of developing depression and clinical anxiety.`}</p>
    <PandemicInformation mdxType="PandemicInformation">
  There may be more conflict among family members during the pandemic. Keep in mind that this can negatively impact <i>all</i> members of the family, including people not directly involved (e.g. younger siblings who witness the conflict). 
    </PandemicInformation>
    <p>{`If you find that your relationship with your teenager or partner involves more shouting than sharing, it is time to make a change.`}</p>
    <p>{`Creating a safe and supportive family environment for your teenager is not about avoiding conflict altogether. Instead, it’s about learning how to manage it.`}</p>
    <p>{`The key to managing disagreements well is to know:`}</p>
    <ul>
      <li parentName="ul">{`what to ignore`}</li>
      <li parentName="ul">{`what to address`}</li>
      <li parentName="ul">{`how to address disagreements fairly and respectfully, and`}</li>
      <li parentName="ul">{`how to show your teenager the value of `}<strong>{`respectful communication`}</strong></li>
    </ul>
    <p>{`Let’s find out how.`}</p>
    <SingleImage smallGridSize={8} gridSize={6} src="/images/m5/19.svg" alt="hero image" mdxType="SingleImage" />

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      